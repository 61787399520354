import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Experience from './components/Experience';
import Projects from './components/Projects';
import About from './components/About';
import Header from './components/Header';

function App() {
  return (
    <Router>
      <div className="bg-white text-gray-900 min-h-screen px-8 lg:px-0 py-12 lg:py-0 overflow-x-hidden">
        <div className="lg:flex">
          <Header/>
          <main className="lg:w-3/5 w-full lg:ml-auto lg:pl-24 lg:pr-48 lg:py-24 lg:overflow-y-auto lg:h-screen">
            <About />
            <Experience />
            <Projects />
            <Footer />
          </main>
        </div>
      </div>
    </Router>
  );
}

function Footer() {
  return (
    <footer className="max-w-md pb-16 text-sm text-black text-left sm:pb-0">
      <p>
        Designed and built with React, Tailwind CSS, and Flask. Deployed with Google App Engine.
      </p>
    </footer>
  );
}

export default App;
