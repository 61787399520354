// Experience.js
import React from 'react';
import Section from './Section';
import { BriefcaseIcon, AcademicCapIcon, BeakerIcon } from '@heroicons/react/24/solid'

const experienceData = [
  {
    years: "2022 — PRESENT",
    title: "Machine Learning Data Engineer",
    company: "ZeroEyes",
    url: "https://zeroeyes.com",
    description: "Developed model-assisted labeling and embeddings pipeline to expand and deduplicate our training data. Decoupled inference with the GPU-optimized NVIDIA Triton Inference Server, increasing speeds up to 193%. Totally refactored the data curation client, integrating CI/CD pipelines and async operations to enhance performance and enable cross-platform compatibility.",
    tools: ["PyTorch", "NVIDIA Triton Server", "Kubernetes", "Docker", "RabbitMQ", "GitHub Actions", "Google Cloud Platform", "TensorRT"],
  },
  {
    years: "JUNE — SEPT 2021",
    title: "Data Science Intern",
    company: "Spotify",
    url: "https://research.atspotify.com/",
    description: "Designed a unified database and pipeline to track the graph hierarchy of Spotify's top 1,000 labels complete with periodic data refreshing and ETL. Developed data driven pathways for organic artist playlists growth.",
    tools: ["Apache Airflow", "BigQuery", "Natural Language Processing", "Tableau", "GraphQL"],
  },
  {
    years: "2018 — 2021",
    title: "Research Assistant",
    company: "Drexel METLab",
    url: "https://drexel.edu/excite/innovation/met-lab/",
    description: "Developed a novel ML algorithm for audio segmentation using object detection on spectral features, resulting in two publications at ISMIR and a Master’s thesis. Created a robotics curriculum with \"plug and play\" devices for K-12 students, used at the 2019 Apple Educators Workshop.",
    tools: ["PyTorch", "Faster R-CNN", "Signal Processing", "C/C++", "Research"],
  }
];

const Experience = () => {
  return (
    <section id="experience" className="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24">
      <div
          className="sticky top-0 z-20 -mx-6 mb-4 w-screen text-left px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
        <h2 className="text-sm font-bold uppercase tracking-widest text-black lg:sr-only">Experience</h2></div>
      <div>
        <ol className="group/list">
          {experienceData.map((exp, index) => (
              <li key={index} className="mb-12">
                <Section {...exp} />
              </li>
          ))}
        </ol>
        <div className="mt-12 justify-start items-start text-start">
        <a href="/resume.pdf" target="_blank" rel="noopener noreferrer" className="inline-flex items-start justify-start text-left font-medium leading-tight font-semibold text-black group">
            <span>
              View Full Résumé
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="inline-block h-4 w-4 shrink-0 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1 group-focus-visible/link:-translate-y-1 group-focus-visible/link:translate-x-1 motion-reduce:transition-none ml-1 translate-y-px">
                    <path fillRule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clipRule="evenodd"/>
              </svg>
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};
export default Experience;