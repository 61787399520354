// Projects.js
import React from 'react';
import { Element } from 'react-scroll';
import Section from './Section'; // Make sure this path is correct
import { StarIcon, UserGroupIcon, TrophyIcon, EnvelopeIcon } from '@heroicons/react/24/solid'

const projectSections = [
  {
    title: "Curie: A Diagnostic Health LLM",
    url: "https://github.com/cuzokwe/HealthLLM",
    description: "Developed a large language model for personalized, up-to-date health diagnoses. Implemented a retrieval-augmented generation pipeline with OpenAI's GPT 3.5, Pinecone, and Streamlit.",
    tools: ["OpenAI", "Pinecone", "Streamlit"],
    accolades: [{ icon: TrophyIcon, title: "2024 ZeroEyes Hackathon Winner", iconColor: "text-yellow-500"}],
    imageSrc: "/images/projects/curie.png"
  },
  {
    title: "uzplaylists: community driven playlist curation",
    description: "Created a music-sharing site with user-driven playlist curation through voting. Built with Django, Tailwind, and JS; deployed on Google App Engine, leveraging GitHub Actions for CI/CD.",
    tools: ["Django", "Tailwind", "JavaScript", "Google App Engine", "GitHub Actions"],
    accolades: [
      { icon: UserGroupIcon, title: "100+ users", iconColor: "text-black-500" },
      { icon: EnvelopeIcon, title: "1000+ submissions" }
    ],
    imageSrc: "/images/projects/uzplaylists.png"
  }
];

const Projects = () => {
  return (
    <section id="projects" className="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24">
      <div
          className="sticky top-0 z-20 -mx-6 mb-4 w-screen text-left px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
        <h2 className="text-sm font-bold uppercase tracking-widest text-black lg:sr-only">Projects</h2></div>
      <div>
        <ul className="group/list">
          {projectSections.map((project, index) => (
              <li key={index} className="mb-12">
                <Section {...project} />
              </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Projects;