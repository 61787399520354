// About.js
import React from 'react';
import { Element } from 'react-scroll';

function About() {
  return (
    <section id="about" className="mb-32 scroll-mt-16 lg:scroll-mt-24">
      <div className="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-white text-left px-6 py-5 md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
        <h2 className="text-sm font-bold uppercase tracking-widest text-black lg:sr-only">About</h2>
      </div>
      <div>
        <p className="mb-4 text-left">
          I'm a Machine Learning Data Engineer from Drexel University with expertise in scalable ML inference systems, data pipelines, and machine learning research.
          Skilled in Python, C++, and various cloud and orchestration technologies.
        </p>
        <p className="mb-4 text-left">
          My main focus these days is building ML powered data pipelines at ZeroEyes, focusing on content understanding with embeddings, or automatic, model-assisted-labeling. My work spans the breadth of signal processing, machine learning, data engineering, and software engineering.
        </p>
        <p className="mb-4 text-left">
          In my free time, I'm traveling, tinkering with new technologies, DJing, or making playlists.
        </p>
      </div>
    </section>
  );
}

export default About;