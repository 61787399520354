import React from 'react';

const Section = ({ years, title, company, url, description, accolades, links, tools, imageSrc }) => {
  return (
    <div className="mb-12">
      <div className="group relative grid pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-70">
        <header className="z-10 mb-2 mt-1 text-xs font-semibold uppercase tracking-wide text-gray-500 sm:col-span-2">
          <div className="flex flex-col items-start">
            {years && <span className="mb-4">{years}</span>}
            {imageSrc && (
              <div className="hidden sm:block w-full">
                <img src={imageSrc} alt={title} className="w-full rounded shadow-sm" />
              </div>
            )}
          </div>
        </header>
        <div className="z-10 sm:col-span-6 text-left">
          <div className="flex flex-col items-start">
            <h3 className="font-medium leading-snug text-gray-800">
              <a className="font-medium leading-tight text-gray-800 hover:text-blue-600 focus-visible:text-blue-600 group/link text-base"
                 href={url} target="_blank" rel="noreferrer">
                <span className="inline-flex items-center">
                  <span>
                    {title}
                    {company && <span> · {company}</span>}
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                       className="inline-block h-4 w-4 shrink-0 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1 group-focus-visible/link:-translate-y-1 group-focus-visible/link:translate-x-1 motion-reduce:transition-none ml-1 translate-y-px">
                    <path fillRule="evenodd"
                          d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z"
                          clipRule="evenodd"/>
                  </svg>
                </span>
              </a>
            </h3>
            <p className="mt-2 text-sm leading-normal text-gray-600">{description}</p>
            {accolades && accolades.length > 0 && (
              <ul className="mt-2 flex flex-wrap" aria-label="Accolades">
                {accolades.map((accolade, index) => (
                  <li key={index} className="mr-4 mt-2">
                    <div className="flex items-center text-sm">
                      {accolade.icon && (
                        <span className="mr-1.5">
                          {typeof accolade.icon === 'string'
                            ? accolade.icon
                            : React.createElement(accolade.icon, {className: `w-4 h-4 ${accolade.iconColor || 'text-gray-700'}`})}
                        </span>
                      )}
                      <span className="font-medium text-gray-700">{accolade.title}</span>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {links && links.length > 0 && (
              <ul className="mt-2 flex flex-wrap" aria-label="Related links">
                {links.map((link, index) => (
                  <li key={index} className="mr-4">
                    <a className="relative mt-2 inline-flex items-center text-sm font-medium text-blue-600 hover:text-blue-800 focus-visible:text-blue-800"
                       href={link.url} target="_blank" rel="noreferrer">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                           className="mr-1 h-3 w-3" aria-hidden="true">
                        <path
                            d="M12.232 4.232a2.5 2.5 0 013.536 3.536l-1.225 1.224a.75.75 0 001.061 1.06l1.224-1.224a4 4 0 00-5.656-5.656l-3 3a4 4 0 00.225 5.865.75.75 0 00.977-1.138 2.5 2.5 0 01-.142-3.667l3-3z"/>
                        <path
                            d="M11.603 7.963a.75.75 0 00-.977 1.138 2.5 2.5 0 01.142 3.667l-3 3a2.5 2.5 0 01-3.536-3.536l1.225-1.224a.75.75 0 00-1.061-1.06l-1.224 1.224a4 4 0 105.656 5.656l3-3a4 4 0 00-.225-5.865z"/>
                      </svg>
                      <span>{link.text}</span>
                    </a>
                  </li>
                ))}
              </ul>
            )}
            {tools && tools.length > 0 && (
              <ul className="mt-2 flex flex-wrap" aria-label="Technologies used">
                {tools.map((tool, index) => (
                  <li key={index} className="mr-1.5 mt-2">
                    <div
                        className="flex items-center rounded-full bg-blue-100 px-3 py-1 text-xs font-medium leading-5 text-blue-800">
                      {tool}
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {imageSrc && (
              <div className="sm:hidden mt-4 w-1/2">
                <img src={imageSrc} alt={title} className="w-full rounded shadow-sm" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section;