import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';


const socialLinks = [
  {
    name: 'GitHub',
    url: 'https://github.com/chrisuzokwe',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-6 w-6" aria-hidden="true">
        <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path>
      </svg>
    ),
  },
  {
    name: 'LinkedIn',
    url: 'https://linkedin.com/in/chrisuzokwe',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6 w-6" aria-hidden="true">
        <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path>
      </svg>
    ),
  },
  {
    name: 'X (Twitter)',
    url: 'https://twitter.com/uzokwexyz',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6 w-6" aria-hidden="true">
        <path d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z"></path>
      </svg>
    ),
  },
  {
    name: 'Instagram',
    url: 'https://instagram.com/uzokwexyz',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6 w-6" aria-hidden="true">
        <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
      </svg>
    ),
  },
];

// Image paths object
const carouselImages = [
  'images/personal/buildspace.jpg',
  'images/personal/phillyhiking.jpg',
  'images/personal/japan1.jpg',
  'images/personal/japan2.jpg',
  'images/personal/recphilly.jpg',
  'images/personal/dreamcon.jpg',
  // Add more image paths as needed
];

function Header() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        (prevIndex + 1) % carouselImages.length
      );
    }, 2500); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <header className="lg:fixed lg:top-0 lg:left-0 lg:h-screen lg:overflow-y-auto lg:flex lg:flex-col lg:justify-between lg:p-8 pb-24 lg:py-24 lg:pl-48 ">
      <div>
        <h1 className="text-3xl font-bold text-gray-900 sm:text-4xl">Christopher Uzokwe</h1>
        <h2 className="mt-3 text-lg font-medium text-gray-700 sm:text-xl">Machine Learning Data Engineer</h2>
        <p className="mt-4 max-w-xs text-gray-600">I build scalable ML systems and optimize data pipelines.</p>
        <nav className="hidden lg:block mt-16">
          <ul className="space-y-5">
            {['About', 'Experience', 'Projects'].map((item) => (
              <li key={item}>
                <Link
                  to={item.toLowerCase()}
                  smooth={true}
                  duration={500}
                  className="group flex items-center py-3 text-sm text-gray-600 hover:text-gray-900 transition-colors duration-300"
                >
                  <span className="mr-4 h-px w-8 bg-gray-300 transition-all group-hover:w-16 group-hover:bg-gray-900"></span>
                  {item}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      {/* Image Carousel */}
      <div className="mt-8 w-full h-40 overflow-hidden">
        <img
          src={carouselImages[currentImageIndex]}
          alt={`Carousel image ${currentImageIndex + 1}`}
          className="w-full h-full object-cover transition-opacity duration-500"
        />
      </div>

      <ul className="mt-8 flex items-center space-x-5" aria-label="Social media">
        {socialLinks.map((link) => (
          <li key={link.name} className="text-xs">
            <a className="block hover:text-black" href={link.url} target="_blank" rel="noreferrer">
              <span className="sr-only">{link.name}</span>
              {link.icon}
            </a>
          </li>
        ))}
      </ul>
    </header>
  );
}

export default Header;